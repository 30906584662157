

body {
  background: var(--color-blue-20);

  &.withBlueBackground {
    background: var(--color-yellow);
  }

  &.withOverlay {
    overflow: hidden;
  }
}

.Layout {
  background: var(--color-white);
}

.PersonHeader,
.MatchHeaderAlt__layout:after {
  background: var(--color-grey-20);
}
